<template>
  <a-card style="background-color: #fff; ">
    <div>
      <a-form layout="horizontal" >
        <div >
          <div class="cus-num">
            <div>
              <span>全部客户：</span>
              <span class="khtext">{{ totalNumber }}</span>
            </div>
            <div style="border-left: 1px solid rgba(85,66,246,0.06);">
              <span>今日新增：</span>
              <span class="khtext">{{ countCustomerToday }}</span>
            </div>
          </div>
       <a-row>
          <a-col>
            <a-form-item label="联系人信息" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
              <a-input v-model="queryData.contactsInfo"  placeholder="姓名/手机号/座机号/微信号/邮箱/qq" />
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item label="客户名称" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
              <a-input v-model="queryData.name" placeholder="请输入客户名称" />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-if="isSearch">
          <a-col>
            <a-form-item label="企业类型" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
              <a-select v-model="queryData.enterpriseType" placeholder="请选择公司类型">
                <a-select-option :value="index + 1" :label="item.name" v-for=" (item, index) in enterpriseType"
                  :key="index">
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item label="客户来源" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
              <a-select v-model="queryData.source" placeholder="请选择来源">
                <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in soureList" :key="index">
                  {{ item.name }}
                </a-select-option>
            </a-select>
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item label="发票信息" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
              <a-input v-model="queryData.invoiceInfo"  placeholder="发票抬头/统一社会信用代码/开户行账号/地址/电话" alt="发票抬头/统一社会信用代码/开户行账号/地址/电话" />
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item label="创建时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
              <a-range-picker :ranges="{'今天': [moment(), moment()], '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],'当月': [moment().startOf('month'), moment().endOf('month')],
'上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
          }" @change="dateChange" :value="dateValue"  />
            </a-form-item>
          </a-col>
          
        </a-row>
        <a-row style="border-bottom:1px solid #D8D8D8;padding-bottom: 6px;">
          <a-col style="display: flex;justify-content: right;">
            <a-button @click="reset">重置</a-button>
             <a-button type="primary" style="margin-left: 10px;" @click="getData">查询</a-button>
          </a-col>
        </a-row>
        <div style="text-align: center;width: 102px;height: 22px;background: #F8F5FF;margin: auto;cursor: pointer;border-radius: 0 0 10px 10px;" @click="isSearch = !isSearch">
          <a-icon v-if="!isSearch" type="caret-down" />
          <a-icon v-else type="caret-up" />
        </div>
        </div>
      </a-form>

      <a-row style="display: flex;margin-top: 10px;">
        <a-checkbox v-model="selectAll" @change="selectAllItems"> 全选 </a-checkbox>
        <div style="display:flex;margin-left: auto;">
          <a-button @click="exportChange(1)" v-if="hasPermission('SHIFT_CUSTOMER')" >批量转移</a-button>
          <a-button @click="addBtn" type="primary" v-if="hasPermission('SAVE_CUSTOMER')" style="margin-left: 8px">新增</a-button>
        </div>
      </a-row>
    </div>
<!--      <div class="custom-scrollbar" :style="{ height: !isSearch ? '64vh' : '44vh' }" v-scroll-bottom="loadMore" ref="leftList">-->
    <div class="custom-scrollbar" :style="{ height: `${computedHeight - 40}px` }" v-scroll-bottom="loadMore" ref="elementRef">
      <a-list bordered>
        <a-list-item v-for="(item, index) in dataSource" :key="index"  @click="toggleSelectedItem(item.id)" :class="{ 'selected-item': current==index}">
          <div style="width: 80%;position: absolute;left: 40px;height: 60px;" @click.stop="updateSelectedItems(index,item)"></div>
          <a-checkbox 
            :checked="selectedItems.includes(item.id)" >
            <div style="display: flex; align-items: center;">
              <!-- <img style="height: 36px; width: 36px; margin-right: 10px;" src="https://qiniu.youjiamall.cn/sfauser.png" alt=""> -->
              
              <div>
                <div>{{ item.name }} <span style="position: absolute; right: 10px;font-size: 12px;">{{ formatDate(item.createTime) }}</span></div>
                <span>{{ item.contactsName }}</span>
              </div>
            </div>
          </a-checkbox>
        </a-list-item>
      </a-list>
      <div v-if="tabLoading && dataSource.length>0" style="text-align: center;">加载中...</div>
      <div style="display:flex;justify-content: center;margin-top:10px;" v-if="hasMoreData">
        <a-button @click="nextPage">下一页</a-button>
      </div>
      <div v-else style="display:flex;justify-content: center;margin-top:10px;">已加载全部数据</div>
      <div style="height: 40px;"></div>
   </div>


    <!-- 创建 -->
    <a-modal title="新增联系人" :visible="visible" :maskClosable="false" @ok="handleOk" :footer="null"
      @cancel="cancelAdd" :width="1100">
      <a-form-model ref="ruleForm" :model="userInfo" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">

        <div style="margin-left:40px;font-weight: 700;font-size: 20px;margin-bottom: 10px;">
          公司信息
        </div>

        <a-row class="form-row" type="flex" justify="center">
          <a-col :span="11">
            <a-form-model-item required  label="公司全称" :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;position: relative;">
                <!-- <a-input @input="handleInput" v-model="userInfo.gsname" placeholder="请输入公司全称" @blur="() => { $refs.name.onFieldBlur(); }" /> -->
                  <a-select
                  show-search
                    label-in-value
                    :value="searchVal"
                    placeholder="请输入公司名称"
                    style="width: 100%"
                    :filter-option="false"
                    :not-found-content="fetching ? undefined : null"
                    @search="fetchUser"
                    @change="handleChange"
                  >
                    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                    <a-select-option v-for="d in searchData" :key="d.Name" @click="selectGs(d)">
                      {{ d.Name }}
                    </a-select-option>
                  </a-select>
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :span="11">
            <a-form-model-item label="公司简称" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;position: relative;">
                <a-input v-model="userInfo.abbreviation" placeholder="请输入公司简称"
                  @blur="() => { $refs.name.onFieldBlur(); }" />
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>


        <a-row class="form-row" type="flex" justify="center">
          <a-col :span="11">
            <a-form-model-item label="公司人数" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <a-input v-model="userInfo.scale" placeholder="请输入公司人数" />
            </a-form-model-item>
          </a-col>
          <a-col :span="11">
            <a-form-model-item label="公司类型" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <a-select v-model="userInfo.enterpriseType" style="width: 100%" placeholder="请选择公司类型">
                <a-select-option :value="index + 1" :label="item.name" v-for=" (item, index) in enterpriseType"
                  :key="index">
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>


        <a-row class="form-row" type="flex" justify="center">
          <a-col :span="11">
            <a-form-model-item label="来源" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <a-select v-model="userInfo.source" style="width: 100%" placeholder="请选择来源">
                <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in soureList" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="11">

          </a-col>
        </a-row>




        <a-form-model-item required label="" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <p style="display:flex;align-items: center;font-size:14px;">
            <span style="color:#f5222d;">*&nbsp;</span>
            预算总额：¥{{ totalWelfareAmount }}
            <a-tooltip placement="topLeft" :title="describeText">
              <a-icon type="question-circle" style="color:#f5222d;font-size: 16px;margin-left: 8px;" />
            </a-tooltip>
          </p>
          <ul style="position: relative;list-style: none;padding:0;width: 1000px;">
            <li v-for="(item,index) in customerWelfareList" :key="item.id" style="display: flex;margin-right: 10px;">
            <!-- <li v-for="item in customerWelfareList" :key="item.id" style="display: flex;margin-right: 10px;"> -->
                  <p style="display: flex;align-items: center;margin-left: 10rpx;">
                    <span>{{ item.describe }}:</span>
                   <a-input style="width:80px;margin: 0 0px 0 10px;" v-model="item.welfareAmount"/>
                  <span style="margin: 0 10px;">元/年</span>
                  </p>
                  <p style="margin-right: 20px;">
                    <span>预计采购月份 : </span>
                    <a-select style="width: 80px;" v-model="item.expectedProcurementMonth" placeholder="月份">
                    <a-select-option :value="it" :label="it" v-for="it in monthDate" :key="it">
                      {{it}}
                    </a-select-option>
                  </a-select>
                  月
                  </p>
                  <a-button type="primary" @click="addWelfare(item)" v-if="!item.showAddButton">添加</a-button>
                  <a-button  @click="delWelfare(index)" v-if="item.showAddButton">删除</a-button>
                </li>
          </ul>
        </a-form-model-item>

        <a-row class="form-row" type="flex" justify="center">
          <a-col :span="24">
            <a-form-model-item label="公司地址" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;margin-bottom: 10px;" v-for="(item, i) in customerAddressList" :key="i">
                <a-select @change="provChange1($event, i)" :index="i" allowClear placeholder="请选择省" v-model="item.provinceName">
                  <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in prov" :key="index">{{
                    item.name
                  }}</a-select-option>
                </a-select>
                <a-select @change="cityChange1($event, i)" :index="i" allowClear placeholder="请选择市" v-model="item.cityName">
                  <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in city" :key="index">{{
                    item.name
                  }}</a-select-option>
                </a-select>
                <a-select @change="areaChange1($event, i)" :index="i" allowClear placeholder="请选择区">
                  <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in area" :key="index">{{
                    item.name
                  }}</a-select-option>
                </a-select>
                <a-input v-model="item.detailedAddress" placeholder="详细地址" />
                <a-button v-if="i == 0" type="primary" style="margin-left: 10px;" @click="addAddress(3, i)">添加</a-button>
                <a-button v-else style="margin-left: 10px;" @click="addAddress(4, i)">删除</a-button>
              </div>
            </a-form-model-item>
          </a-col>

        </a-row>

        <div style="margin-left:40px;font-weight: 700;font-size: 20px;margin-bottom: 10px;">
          个人信息
        </div>

        <a-row class="form-row" type="flex" justify="center">
          <a-col :span="11">
            <a-form-model-item required ref="name" prop="name" label="联系人" :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;position: relative;">
                <a-input v-model="userInfo.name" placeholder="请输入联系人" />
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :span="11">
            <a-form-model-item label="联系人角色" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <a-select v-model="userInfo.role" style="width: 100%" placeholder="请选择联系人角色">
                <a-select-option :value="index + 1" :label="item.name" v-for=" (item, index) in roleList" :key="index">
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row class="form-row" type="flex" justify="center">
          <a-col :span="11">
            <a-form-model-item label="客户经理" ref="customerManagerIdList" prop="customerManagerIdList"
              :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <a-select v-model="userInfo.customerManagerIdList" mode="multiple" style="width: 100%"
                placeholder="请选择客户经理">
                <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in users" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="11">
            <a-form-model-item label="性别" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <a-select v-model="userInfo.sex" style="width: 100%" placeholder="请选择联系人性别">
                <a-select-option :value="index + 1" :label="item.name" v-for=" (item, index) in sexList" :key="index">
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row class="form-row" type="flex" justify="center">
          <a-col :span="11">
            <a-form-model-item label="生日" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;position: relative;">
                <a-date-picker style="width: 100%;" @change="birthdayChange" placeholder="请选择联系人生日" />
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :span="11">
            <a-form-model-item label="备注" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;position: relative;">
                <a-input v-model="userInfo.remarks" placeholder="请输入备注" @blur="() => { $refs.name.onFieldBlur(); }" />
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>      
  
        <a-form-model-item required label="联系人联系方式" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <div style="display: flex;" class="lx">
            <div style="position: relative;flex: 1;">
              <div v-for="(item, index) in phone" :key="index" style="display: flex;margin-bottom: 4px;">
                <a-select :disabled="index == 0" label-in-value :default-value="{ key: '手机号' }"
                  style="width: 120px; margin: 0 4px;" @change="phoneChange($event, index)">
                  <a-select-option value="1">手机号</a-select-option>
                  <a-select-option value="2">座机号</a-select-option>
                  <a-select-option value="3">邮箱</a-select-option>
                  <a-select-option value="4">微信号</a-select-option>
                  <a-select-option value="5">QQ号</a-select-option>
                  <a-select-option value="6">其他</a-select-option>
                </a-select>
                <a-form-model-item required style="width:100%;margin-top: -2px;">
                  <a-input v-if="item.type == 1" placeholder="请输入联系人手机号" v-model="item.contactInformation"
                    @blur="() => { $refs.phone.onFieldBlur(); }" />
                  <a-input v-if="item.type == 2" placeholder="请输入联系人座机号" v-model="item.contactInformation"
                    @blur="() => { $refs.phone.onFieldBlur(); }" />
                  <a-input v-if="item.type == 3" placeholder="请输入联系人邮箱" v-model="item.contactInformation"
                    @blur="() => { $refs.phone.onFieldBlur(); }" />
                  <a-input v-if="item.type == 4" placeholder="请输入联系人微信号" v-model="item.contactInformation"
                    @blur="() => { $refs.phone.onFieldBlur(); }" />
                  <a-input v-if="item.type == 5" placeholder="请输入联系人QQ号" v-model="item.contactInformation"
                    @blur="() => { $refs.phone.onFieldBlur(); }" />
                  <a-input v-if="item.type == 6" placeholder="请输入其他联系方式" v-model="item.contactInformation"
                    @blur="() => { $refs.phone.onFieldBlur(); }" />
                </a-form-model-item>
                <a-button v-if="index > 0" type="error" @click="delIpt(0, index)">删除</a-button>
              </div>
            </div>
            <a-button type="primary" style="margin-left: 10px;" @click="addIpt(0)">添加</a-button>
          </div>
          <div style="display: flex;margin-top: 10px;" class="lx">
            <div style="position: relative;flex: 1;">
              <div v-for="(item, index) in wechat" :key="index" style="display: flex;margin-bottom: 4px;">
                <a-select :disabled="index == 0" label-in-value :default-value="{ key: '微信号' }"
                  style="width: 120px; margin: 0 4px;" @change="weChatChange($event, index)">
                  <a-select-option value="1">手机号</a-select-option>
                  <a-select-option value="2">座机号</a-select-option>
                  <a-select-option value="3">邮箱</a-select-option>
                  <a-select-option value="4">微信号</a-select-option>
                  <a-select-option value="5">QQ号</a-select-option>
                  <a-select-option value="6">其他</a-select-option>
                </a-select>
                <a-form-model-item required style="width:100%;margin-top: -2px;">
                  <a-input v-if="item.type == 1" placeholder="请输入联系人手机号" v-model="item.contactInformation"
                    @blur="() => { $refs.wechat.onFieldBlur(); }" />
                  <a-input v-if="item.type == 2" placeholder="请输入联系人座机号" v-model="item.contactInformation"
                    @blur="() => { $refs.wechat.onFieldBlur(); }" />
                  <a-input v-if="item.type == 3" placeholder="请输入联系人邮箱" v-model="item.contactInformation"
                    @blur="() => { $refs.wechat.onFieldBlur(); }" />
                  <a-input v-if="item.type == 4" placeholder="请输入联系人微信号" v-model="item.contactInformation"
                    @blur="() => { $refs.wechat.onFieldBlur(); }" />
                  <a-input v-if="item.type == 5" placeholder="请输入联系人QQ号" v-model="item.contactInformation"
                    @blur="() => { $refs.wechat.onFieldBlur(); }" />
                  <a-input v-if="item.type == 6" placeholder="请输入其他联系方式" v-model="item.contactInformation"
                    @blur="() => { $refs.wechat.onFieldBlur(); }" />
                </a-form-model-item>

                <a-button v-if="index > 0" type="error" @click="delIpt(1, index)">删除</a-button>
              </div>
            </div>
            <a-button type="primary" style="margin-left: 10px;" @click="addIpt(1)">添加</a-button>
          </div>

          <div style="display: flex;margin-top: 10px;" class="lx">
            <div style="position: relative;flex: 1;">
              <div v-for="(item, index) in zuoji" :key="index" style="display: flex;margin-bottom: 4px;">
                <a-select :disabled="index == 0" label-in-value :default-value="{ key: '座机号' }"
                  style="width: 120px; margin: 0 4px;" @change="zuojiChange($event, index)">
                  <a-select-option value="1">手机号</a-select-option>
                  <a-select-option value="2">座机号</a-select-option>
                  <a-select-option value="3">邮箱</a-select-option>
                  <a-select-option value="4">微信号</a-select-option>
                  <a-select-option value="5">QQ号</a-select-option>
                  <a-select-option value="6">其他</a-select-option>
                </a-select>

                <a-form-model-item required style="width:100%;margin-top: -2px;">
                  <a-input v-if="item.type == 1" placeholder="请输入联系人手机号" v-model="item.contactInformation"
                    @blur="() => { $refs.zuoji.onFieldBlur(); }" />
                  <a-input v-if="item.type == 2" placeholder="请输入联系人座机号" v-model="item.contactInformation"
                    @blur="() => { $refs.zuoji.onFieldBlur(); }" />
                  <a-input v-if="item.type == 3" placeholder="请输入联系人邮箱" v-model="item.contactInformation"
                    @blur="() => { $refs.zuoji.onFieldBlur(); }" />
                  <a-input v-if="item.type == 4" placeholder="请输入联系人微信号" v-model="item.contactInformation"
                    @blur="() => { $refs.zuoji.onFieldBlur(); }" />
                  <a-input v-if="item.type == 5" placeholder="请输入联系人QQ号" v-model="item.contactInformation"
                    @blur="() => { $refs.zuoji.onFieldBlur(); }" />
                  <a-input v-if="item.type == 6" placeholder="请输入其他联系方式" v-model="item.contactInformation"
                    @blur="() => { $refs.zuoji.onFieldBlur(); }" />
                </a-form-model-item>
                <a-button v-if="index > 0" type="error" @click="delIpt(2, index)">删除</a-button>
              </div>
            </div>
            <a-button type="primary" style="margin-left: 10px;" @click="addIpt(2)">添加</a-button>
          </div>
        </a-form-model-item>


        <a-form-model-item label="联系人收货地址" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <div style="display: flex;margin-bottom: 10px;" v-for="(item, i) in sfaContactAddressList" :key="i">
            <a-form-model-item style="width:100%;margin-top: -2px;">
              <a-input v-model="item.consignee" placeholder="请输入收货人" />
            </a-form-model-item>
            <a-form-model-item style="width:100%;margin-top: -2px;">
              <a-input v-model="item.mobile" placeholder="收货人手机号" />
            </a-form-model-item>

            <a-form-model-item style="width:100%;margin-top: -2px;">
              <a-select @change="provChange($event, i)" :index="i" allowClear placeholder="请选择省" v-model="item.provinceName">
                <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in prov" :key="index">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item style="width:100%;margin-top: -2px;">
              <a-select @change="cityChange($event, i)" :index="i" allowClear placeholder="请选择市" v-model="item.cityName">
                <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in city" :key="index">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item style="width:100%;margin-top: -2px;">
              <a-select @change="areaChange($event, i)" :index="i" allowClear placeholder="请选择区">
                <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in area" :key="index">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-input v-model="item.detailedAddress" placeholder="小区门牌号" />
            <a-button v-if="i == 0" type="primary" style="margin-left: 10px;" @click="addAddress(0, i)">添加</a-button>
            <a-button v-else style="margin-left: 10px;" @click="addAddress(1, i)">删除</a-button>
          </div>
        </a-form-model-item>




      </a-form-model>

      <div style="display: flex;justify-content: center;">
        <a-button type="primary" style="margin-right: 10px;" @click="handleOk">保存</a-button>
      </div>
    </a-modal>

    <!-- 转移 -->
    <a-modal title="转移联系人" :visible="exportVisible" :maskClosable="false" @ok="exportHandle"
      @cancel="exportVisible = false" :width="700">
      <a-select mode="multiple" v-model="queryData.customerManagerIdList"
      :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
      style="width: 100%" placeholder="请选择归属人">
        <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in users" :key="index">
          {{ item.name }}
        </a-select-option>
      </a-select>
    </a-modal>
  </a-card>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
import { mapGetters } from 'vuex'

const columns = [
  {
    title: '创建时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' },
    align: 'center',
    width: 100,
  },
  {
    title: '公司名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
    ellipsis: true,
    align: 'center',
    width: 150

  },
  {
    title: '公司人数',
    dataIndex: 'scaleName',
    align: 'center',
    ellipsis: true,
    scopedSlots: { customRender: 'scaleName' },
    width: 100
  },
  {
    title: '企业类型',
    dataIndex: 'enterpriseTypeName',
    align: 'center',
    ellipsis: true,
    scopedSlots: { customRender: 'enterpriseTypeName' },
    width: 100
  },
  {
    title: '预算总额',
    dataIndex: 'budgetAmount',
    align: 'center',
    width: 100
  },
  {
    title: '客户经理',
    dataIndex: 'customerManagerName',
    align: 'center',
    ellipsis: true,
    width: 100
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' },
    align: 'center',
    fixed: 'right',
    width: 200
  }
]
import debounce from 'lodash/debounce';

export default {
  name: 'list',
  inject: ['reload'],
  data() {
    this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 900);
    return {
      searchData: [],
      searchVal: "",
      fetching: false,
      isSearch:false,//展开嗖嗖
      current:0,
      selectedItems:[],
      selectAll: false,
      // 树形
      treeExpandedKeys: [],

      userInfo: { budgetAmount: 0, customerManagerIdList: [] },
      // 公司人数
      scaleList: [{ id: 1, name: '50人以下' }, { id: 2, name: '50-99人' }, { id: 3, name: '100-499人' }, { id: 4, name: '500-999人' }, { id: 5, name: '1000-9999人' }, { id: 6, name: '10000人以上' },],
      // 类型
      enterpriseType: ['医院', '街道', '医院', '综合', '咨询/设备/技术公司', '科技公司', '出版社', '酒店', '工程有限公司', '银行', '事业单位', '路桥建设', '税务局', '卫生局', '审计局', '进出口贸易', '园林局', '学校', '其他'],
      // 来源
      soureList: [{name:'天眼查',id:1},{name:'企查查',id:2}, {name:'陌生拜访',id:3}, {name:'朋友介绍',id:4}, {name:'客户转介绍',id:6},  {name:'其他',id:5}],
      // 联系人列表
      sfaContactsList: [{}],
      // 收货地址列表
      sfaContactAddressList: [{
        "consignee": "",
        "mobile": "",
        "provinceCode": "110000",
        "provinceName": "北京市",
        "cityCode": "110100",
        "cityName": "北京市",
        "countyCode": "",
        "countyName": "",
        "detailedAddress": ""
      }],
      // 公司地址
      customerAddressList: [{
        "provinceCode": '110000',
        "provinceName": "北京市",
        "cityCode": '110100',
        "cityName": "北京市",      
        "countyCode": '',
        "countyName": "",
        "detailedAddress": ""
      }],
      selectedValue: '',
      // 福利列表
      customerWelfareList: [
        {
          "welfareId": "2GBge756Ad",
          expectedProcurementMonth:0,
          "welfareAmount": 0
        }, {
          "welfareId": "uMutq2tNYO",
          expectedProcurementMonth:0,
          "welfareAmount": 0
        }, {
          "welfareId": "Euw01T1K1T",
          expectedProcurementMonth:0,
          "welfareAmount": 0
        }, {
          "welfareId": "KfY1Ei3gQi",
          expectedProcurementMonth:0,
          "welfareAmount": 0
        }, {
          "welfareId": "JxBVvK5vJX",
          expectedProcurementMonth:0,
          "welfareAmount": 0
        }, {
          "welfareId": "lltjBncXD3",
          expectedProcurementMonth:0,
          "welfareAmount": 0
        }, {
          "welfareId": "Bb1qHXRyZi",
          expectedProcurementMonth:0,
          "welfareAmount": 0
        }
      ],
      monthDate:["01","02","03","04","05","06","07","08","09","10","11","12"],
      countCustomerToday: 0,//今日新增
      totalNumber: 0,//全部
      expandedRowKeys: [],
      iptmd: 4,
      iptsm: 22,
      moment,
      customerData: [],
      advanced: true,
      columns: columns,
      dataSource: [],
      tabLoading: false,
      hasMoreData: true, // 标志是否还有更多数据可供加载
      loading:false,
      selectedRows: [],
      total: 0,
      // 筛选
      queryData: {
        page: 0,
         size: 20,
        retrievalType:1
      },
      currentPage: 1,
      // 创建表单
      form: {
        phone: [{ value: '' }],
        customerId: [],
        type: [],
        productPrice: '',
        productBudget: '',
        businessExpenses: '',
        purchaseQuantity: '',
        expectedProcurementTime: '',
        remarks: ''
      },
      // 新建方案弹窗
      labelCol: { span: 8 },
      wrapperCol: { span: 24 },
      rules: {
        name: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        // gsname: [{ required: true, message: '请输入公司全称', trigger: 'change' }],
        role: [{ required: true, message: '请选择角色', trigger: 'change' }],
        customerManagerIdList: [{ required: true, message: '请选择客户经理', trigger: 'change' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        phone: [{ required: true, message: '请输入号码', trigger: 'blur' }],
        wechat: [{ required: true, message: '请输入号码', trigger: 'blur' }],
        zuoji: [{ required: true, message: '请输入号码', trigger: 'blur' }],
        consignee: [{ required: true, message: '请输入收货人', trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入收货人电话', trigger: 'blur' }],
        abbreviation: [{ required: true, message: '请输入公司简称', trigger: 'blur' }],
        // scale: [{ required: true, message: '请选择公司人数', trigger: 'change' }],
        scale: [{ required: true, message: '请输入公司人数', trigger: 'blur' }],
        enterpriseType: [{ required: true, message: '请选择公司类型', trigger: 'change' }],
        source: [{ required: true, message: '请选择来源', trigger: 'change' }],
      },
      roleList: ["未知", "总负责人", "重要决策人", "办事人员", "其他"],
      sexList: ["男", "女", "未知"],
      phone: [{ contactInformation: "", type: '1' }],
      wechat: [{ contactInformation: "", type: '4' }],
      zuoji: [{ contactInformation: "", type: '2' }],
      prov: [],
      city: [],
      area: [],
      visible: false,
      welfare: [],
      customerManagerIdList: [], //客户经理id
      users: [],

      exportVisible: false,

      selectedRowKeys: [], // Check here to configure the default column

      exportIdArr: [],
      describeText: "",
      computedHeight:0,


      gsAddress: [],//筛选公司地址
      gsCity: [],
      gsArea: [],
      dateValue:"",

    }
  },
  computed: {
    ...mapGetters('account', ['user']),
    totalWelfareAmount() {
      return this.customerWelfareList.reduce((total, item) => {
        this.userInfo.budgetAmount = total + Number(item.welfareAmount);
        return total + Number(item.welfareAmount);
      }, 0);
    },
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        onSelection: this.onSelection,
      };
    },
    userPermissions() {
        return this.$store.state.account.user.authorityList;
    }
  },
  mounted() {
    this.computeElementHeight(); // 计算初始高度
    window.addEventListener('resize', this.handleResize); // 监听窗口大小变化事件
    window.addEventListener('scroll', this.handleScroll);
    // 获取省
    this.getCity();
    // 获取列表
    this.getData();
    this.initData();
    
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize); // 组件销毁前移除事件监听

    window.removeEventListener('scroll', this.handleScroll);
  },
  directives: {
  'scroll-bottom': {
    bind(el, binding) {
      el.addEventListener('scroll', function() {
        if (el.scrollHeight - el.scrollTop === el.clientHeight) {
          binding.value();
        }
      });
    }
  }
},
  methods: {
    fetchUser(value) {
      if(!value) {
        return false;
      }
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.searchData = [];
      this.fetching = true;

      // 使用 request 函数从指定接口获取数据
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/findEnterpriseByName/'+value, 'get').then(res => {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return;
          }
          this.searchData = res.data.data;
          this.fetching = false;
        })
        .catch(error => {
          console.error('Error fetching user:', error);
          // 处理错误
        });
    },
    handleChange(searchVal) {
      Object.assign(this, {
        searchVal,
        searchData: [],
        fetching: false,
      });
    },
    selectGs(data){
      this.userInfo.creditCode = data.CreditCode
      this.userInfo.establishTime = data.StartDate
      this.userInfo.representativeName = data.OperName
      this.userInfo.customerStatus = data.Status
      this.userInfo.registerNumber = data.KeyNo
      this.userInfo.registerAddress = data.Address
    },
    initData(){
      // 预算列表
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/welfare/list', 'get').then(res => {
        this.welfare = res.data.data;
        this.welfare.forEach(item => {
          this.customerWelfareList.forEach((son) => {
            if (item.id == son.welfareId) {
              son.describe = item.describe
              this.describeText = this.describeText + item.describe + '+'
            }
          })
        })
        this.describeText = this.describeText.replace(/\+$/, '')
      })
    //  客户经理 
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/staff/list/1', 'get').then(res => {
      this.users = res.data.data;
      this.userInfo.customerManagerIdList = [this.$store.state.account.user.id]
    })
    },
    // 添加预算
    addWelfare(item){
        // 找到被点击按钮的索引
        const index = this.customerWelfareList.indexOf(item);
        if (index > -1) {
          // 创建一个新对象，内容与传入的 item 相同，但标记为不需要展示添加按钮
          const newItem = Object.assign({}, item); // 或者使用扩展运算符：const newItem = { ...item, showAddButton: false };
          newItem.expectedProcurementMonth = 0
          newItem.welfareAmount = 0
          newItem.showAddButton = 1
          // 在索引后面插入新的元素
          this.customerWelfareList.splice(index + 1, 0, newItem);
        }
    },
    delWelfare(index) {
      this.customerWelfareList.splice(index,1);
    },
    handleInput(e){
      this.userInfo.gsname = e.target.value.replace(/[^\u4e00-\u9fa5a-zA-Z0-9]/g, '');
    },
    computeElementHeight() {
      const element = this.$refs.elementRef;
      const rect = element.getBoundingClientRect();
      const distanceToTop = rect.top;
      this.computedHeight = window.innerHeight - distanceToTop;
    },
    handleResize() {
      this.computeElementHeight(); // 窗口大小变化时重新计算高度
    },

  nextPage(){
    this.tabLoading = true;
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/list', 'post', this.queryData).then(res => {
      let data = res.data.data;
      this.tabLoading = false;
      if (res.data.code === 200) {
        if (data.data.length > 0) {
          data.data.forEach(item => {
            this.dataSource.push(item);
          });
          this.queryData.page++; // 增加页码以请求下一页数据
          this.total = data.total;
        } else {
          this.hasMoreData = false; // 没有更多数据可供加载
        }
      } else {
        this.$message.error(res.data.message);
      }
    }).catch(error => {
      console.error('Error loading more data:', error);
    });
  },
  loadMore() {
      if (this.tabLoading || !this.hasMoreData) return;
      this.tabLoading = true;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/list', 'post', this.queryData).then(res => {
        let data = res.data.data;
        this.tabLoading = false;
        if (res.data.code === 200) {
          if (data.data.length > 0) {
            data.data.forEach(item => {
              this.dataSource.push(item);
            });
            this.queryData.page++; // 增加页码以请求下一页数据
            this.total = data.total;
          } else {
            this.hasMoreData = false; // 没有更多数据可供加载
          }
        } else {
          this.$message.error(res.data.message);
        }
      }).catch(error => {
        console.error('Error loading more data:', error);
      });
},
handleScroll() {
  let scrollHeight = document.documentElement.scrollHeight;
  let clientHeight = document.documentElement.clientHeight;
  let scrollTop = document.documentElement.scrollTop;
  if (scrollHeight - clientHeight - scrollTop <= 100 && this.hasMoreData) {
    this.loadMore();
  }
},
toggleSelectedItem(itemId) {
    if (this.selectedItems.includes(itemId)) {
      this.selectedItems = this.selectedItems.filter(id => id !== itemId);
    } else {
      this.selectedItems.push(itemId);
    }
  },
    updateSelectedItems(index,item) {
      this.current = index;
      this.$emit('selectListChange',item)
    },
    selectAllItems() {
    if (this.selectAll) {
      this.selectedItems = this.dataSource.map(item => item.id);
    } else {
      this.selectedItems = [];
    }
  },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    
    // 重置搜索
    reset() {
      this.queryData = {
        page: 0,
         size: 20,
        retrievalType:1
      }
      this.dateValue = ""
      this.retrievalType = []
      this.getData()
    },
    hasPermission(permission) {
      return this.userPermissions.includes(permission);
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    addBtn() {
      this.visible = true;
      this.userInfo.customerManagerIdList = [this.$store.state.account.user.id]
      this.phone = [{ contactInformation: "", type: '1' }]
      this.wechat = [{ contactInformation: "", type: '4' }]
      this.zuoji = [{ contactInformation: "", type: '2' }]
    },
    //转移
    exportChange() {
        this.exportVisible = true;
      // this.exportIdArr = [];
      // if (type == 0) {
      //   this.exportIdArr[0] = e;
      //   this.exportVisible = true;
      // } else {
      //   if (this.selectedRowKeys.length > 0) {
      //     this.exportVisible = true;
      //     this.selectedRowKeys.forEach(item => {
      //       this.exportIdArr.push(this.dataSource[item].id)
      //     })
      //   }
      // }
    },
    phoneChange(e, index) {
      this.phone[index].type = ''
      this.phone[index].type = e.key;
    },
    weChatChange(e, index) {
      this.wechat[index].type = ''
      this.wechat[index].type = e.key;
    },
    zuojiChange(e, index) {
      this.zuoji[index].type = ''
      this.zuoji[index].type = e.key;
    },
    exportHandle() {
      if (this.selectedItems.length == 0) {
        this.$message.error('请选择客户');
        return false;
      }
      // if (this.exportIdArr.length == 0) {
      //   this.$message.error('请选择客户');
      //   return false;
      // }
      if (!this.queryData.customerManagerIdList) {
        this.$message.error('请选择转移人');
        return false;
      }
      let params = []
      this.selectedItems.forEach(item => {
        params.push({ customerId: item, staffIdList: this.queryData.customerManagerIdList })
      })
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/shift', 'post', params).then(res => {
        if (res.data.code == 200) {
          this.$message.success('转移成功！')
          this.exportVisible = false;
          this.queryData.customerManagerIdList = [];
          this.selectedItems = [];
          this.getData()
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    checkEmptyProperty(obj) {
      return Object.values(obj).every(val => val !== '');
    },
    cancelAdd() {
      this.userInfo = {}
      this.visible = false;
    },
    hasNonNumeric(inputString) {
      return /\D/.test(inputString);
    },
    // 保存
    handleOk(e) {
      e.preventDefault()
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if(this.zuoji.length > 0) {
            for (let i = 0; i < this.zuoji.length; i++) {
              if(this.zuoji[i].type == 2) {
                if (this.zuoji[i].contactInformation != "" && this.zuoji[i].contactInformation.length < 10 ) {
                  this.$message.error('请输入完整的座机号，包括区号！');
                  return; // 当座机号不满11位时立即返回提示信息
                }
                
                if (this.hasNonNumeric(this.zuoji[i].contactInformation)) {
                  this.$message.error('座机号仅限数字！');
                  return; // 当发现非数字字符时立即停止循环
                }
              }
            }
          }
          if (this.phone[0].contactInformation == "" && this.wechat[0].contactInformation == "" && this.zuoji[0].contactInformation == "") {
            this.$message.error('请补全联系方式')
            return false;
          }

          // 校验福利金额
          let fare = true;
          this.customerWelfareList.forEach((obj) => {
            for (const key in obj) {
              if (Object.prototype.hasOwnProperty.call(obj, key)) {
                const value = obj[key];
                if (!value && value !== 0) {
                  fare = false;
                }
              }
            }
          });
          if (!fare) {
            this.$message.error('福利金额不可为空！');
            return false;
          }
          // 过滤空联系号码框
          let arr = this.phone.concat(this.wechat, this.zuoji);
          let mationList = arr.filter(obj => {
            for (let key in obj) {
              if (obj[key] === '') {
                return false; // 
              }
            }
            return true;
          });


          // 过滤空地址
          let sfaContactAddressList = this.sfaContactAddressList
          sfaContactAddressList = this.sfaContactAddressList.map(obj => {
            for (let key in obj) {
              if (obj[key] === '') {
                delete obj[key];
              }
            }
            return obj;
          }).filter(obj => Object.values(obj).some(value => value !== ''));
          let customerAddressList = this.customerAddressList;
          customerAddressList = this.customerAddressList.map(obj => {
            for (let key in obj) {
              if (obj[key] === '') {
                delete obj[key];
              }
            }
            return obj;
          }).filter(obj => Object.values(obj).some(value => value !== ''));
          if(!this.searchVal.key) {
            this.$message.error('未选择公司！')
            return false;
          }
          let params = {
            "name": this.searchVal.key,
            "type": 1,
            "scale": this.userInfo.scale,
            "enterpriseType": this.userInfo.enterpriseType,
            "remarks": this.userInfo.remarks,
            "abbreviation": this.userInfo.abbreviation,
            "source": this.userInfo.source,
            "budgetAmount": this.userInfo.budgetAmount,
            "customerManagerIdList": this.userInfo.customerManagerIdList,
            "customerWelfareList": this.customerWelfareList,
            "customerAddressList": customerAddressList,
            "creditCode":this.userInfo.creditCode,
            "establishTime":this.userInfo.establishTime,
            "representativeName":this.userInfo.representativeName,
            "customerStatus":this.userInfo.customerStatus,
            "registerNumber":this.userInfo.registerNumber,
            "registerAddress":this.userInfo.registerAddress,
            "sfaContactsList": [{
              "name": this.userInfo.name,
              "role": this.userInfo.role,
              "sex": this.userInfo.sex,
              "birthday": this.userInfo.birthday,
              "sfaContactInformationList": mationList,
              "sfaContactAddressList": sfaContactAddressList
            }
            ]
          }
          request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/check', 'post', params).then(res => {
            if (res.data.data) {
              this.$message.warning(res.data.data)
              return false;
            }
            this.$message.loading();
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/save', 'post', params).then(res => {
              this.$message.destroy()
              if (res.data.code == 200) {
                this.$message.success('添加成功！');
                this.visible = false;
                this.getData();
                this.reload();
              } else {
                this.$message.error(res.data.message);
              }
            })
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    // 添加地址
    addAddress(type, i) {
      if (type == 3 || type == 4) {
        if (type == 3) {
          this.customerAddressList.push({})
        } else {
          this.customerAddressList.splice(i, 1)
        }
        return false;
      }
      if (type == 0) {
        this.sfaContactAddressList.push({})
      } else {
        this.sfaContactAddressList.splice(i, 1)
      }
    },
    getCity() {
      request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/0', 'get').then(res => {
        if (res.data.code == 200) {
          this.prov = res.data.data;
          this.gsAddress = res.data.data;
        } else {
          this.$message.error(res.data.message)
        }
      })
      // 市
      request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/110000', 'get').then(res => {
        if (res.data.code == 200) {
          this.city = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      })
      // 区
      request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/110100', 'get').then(res => {
        if (res.data.code == 200) {
          this.area = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    provChange(id, index) {
      const selProv = this.prov.find(item => item.id === id);
      this.sfaContactAddressList[index].provinceCode = selProv.id;
      this.sfaContactAddressList[index].provinceName = selProv.name;
      this.sfaContactAddressList[index].cityCode = '';
      this.sfaContactAddressList[index].cityName = '';
      this.sfaContactAddressList[index].countyCode = '';
      this.sfaContactAddressList[index].countyName = '';
      request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
        if (res.data.code == 200) {
          this.city = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    cityChange(id, index) {
      const selProv = this.city.find(item => item.id === id);
      this.sfaContactAddressList[index].cityCode = selProv.id;
      this.sfaContactAddressList[index].cityName = selProv.name;
      this.sfaContactAddressList[index].countyCode = '';
      this.sfaContactAddressList[index].countyName = '';
      request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
        if (res.data.code == 200) {
          this.area = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    areaChange(id, index) {
      const selProv = this.area.find(item => item.id === id);
      this.sfaContactAddressList[index].countyCode = selProv.id;
      this.sfaContactAddressList[index].countyName = selProv.name;
    },

    provChange1(id, index) {
      const selProv = this.prov.find(item => item.id === id);
      this.customerAddressList[index].provinceCode = selProv.id;
      this.customerAddressList[index].provinceName = selProv.name;
      this.customerAddressList[index].cityCode = '';
      this.customerAddressList[index].cityName = '';
      this.customerAddressList[index].countyCode = '';
      this.customerAddressList[index].countyName = '';
      request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
        if (res.data.code == 200) {
          this.city = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    cityChange1(id, index) {
      const selProv = this.city.find(item => item.id === id);
      this.customerAddressList[index].cityCode = selProv.id;
      this.customerAddressList[index].cityName = selProv.name;
      this.customerAddressList[index].countyCode = '';
      this.customerAddressList[index].countyName = '';
      request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
        if (res.data.code == 200) {
          this.area = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    areaChange1(id, index) {
      const selProv = this.area.find(item => item.id === id);
      this.customerAddressList[index].countyCode = selProv.id;
      this.customerAddressList[index].countyName = selProv.name;
    },
    handlePageChange(page) {
      this.queryData.page = page - 1;
      this.currentPage = page;
      this.getData()
    },
    // 格式化年月日
    formatDate(timestamp) {
      const date = new Date(timestamp);
      // const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      let result = `${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
      return result
    },
    // 选择日期
    dateChange(date, dateString) {
      console.log(date, dateString);
      this.queryData.startTime = dateString[0].toString();
      this.queryData.endTime = dateString[1].toString();
      this.dateValue = date
    },
    resetQuery() {
      this.queryData = {
        page: 0,
         size: 20,
        name: '',
        phone: '',
        address: '',
        dateMax: '',
        dateMin: '',
        startTime: '',
        endTime: '',
        remarks: ''
      }
      this.getData()
    },
    birthdayChange(date, dateString) {
      this.userInfo.birthday = dateString
    },
    createChange(date, dateString) {
      this.userInfo.createTime = dateString
    },
    addIpt(i) {
      if (i == 0) {
        this.phone.push({ contactInformation: "", type: "1" });
      }
      if (i == 1) {
        this.wechat.push({ contactInformation: "", type: "4" });
      }
      if (i == 2) {
        this.zuoji.push({ contactInformation: "", type: "2" });
      }
      console.log(this.zuoji,'this.zuoji')
    },
    delIpt(type, i) {
      if (type == 0) {
        this.phone.splice(i, 1)
      } else if (type == 1) {
        this.wechat.splice(i, 1)
      } else {
        this.zuoji.splice(i, 1)
      }
    },
    // 获取列表 
    getData() {
      this.tabLoading = true;
      this.queryData.page = 0
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/list', 'post', this.queryData).then(res => {
        let data = res.data.data;
        this.tabLoading = false;
        if (res.data.code == 200) {
          this.dataSource = data.data;
          this.$emit('selectListChange',data.data[0])
          this.queryData.page = data.page + 1;
          this.queryData.size = data.size;
          this.total = data.total;

        } else {
          this.$message.error(res.data.message)
        }
      })
      // 查询当日新增
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/countCustomer', 'post', {retrievalType:1}).then(res => {
        let data = res.data.data;
        this.countCustomerToday = data.toDayNumber;
        this.totalNumber = data.totalNumber;
      })
    },
    // 是否确认删除
    delConfirm(e) {
      this.$message.loading();
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/' + e.id, 'delete').then(res => {
        this.$message.destroy();
        if (res.data.code == 200) {
          this.$message.success('删除成功')
          this.getData()
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>

.fold {
  width: calc(100% - 216px);
  display: inline-block
}

.operator {
  margin-bottom: 18px;
}

.cre-btn {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #EEECFE;
  border-radius: 4px;
  border: 1px dashed #5542F6;
  cursor: pointer;
  font-size: 16px;
  font-family: PingFangHK-Regular, PingFangHK;
  font-weight: 400;
  color: #5542F6;
}

.btn-box button {
  width: 106px;
  height: 36px;
  margin-left: 10px;
}



.page-box {
  display: flex;
  justify-content: right;
  padding-right: 46px;
  margin-top: 10px;
}

::v-deep .ant-card-body {
  padding-top: 0;
}



.top-text p {
  height: 10px;
}

.top-text div {
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-right: 20px;
  padding: 2px 20px;
  box-sizing: border-box;
  margin-top: 20px;
}

.top-form {
  box-sizing: border-box;
}

.top-form .ant-form-item {
  margin-right: 20px !important;
}

::v-deep .top-form .ant-col-6 {
  width: auto !important;
}


::v-deep .ant-col-6 {
  width: auto !important;
}


.custom-scrollbar {
  margin-top: 10px;
  position: relative;
  overflow-y: scroll;
  // height: 70vh;
}

.cus-num {
display:flex;
background: #F4F0FF;
height: 50px;
width: 90%;
border-radius: 10px;
padding: 0 14px;
box-sizing: border-box;
align-items: center;
margin: 10px auto;
}
.cus-num div{
flex: 1;
text-align: center;
}
.khtext {
font-family: DINAlternate, DINAlternate;
font-weight: bold;
font-size: 20px;
color: #5542F6;
text-align: left;
font-style: normal;
text-transform: none;
}
.top-ipt {
position: fixed;
width: 20vw;
left: 190px;
// height: 20vh;
z-index: 999;
background: #fff;
}


.ant-checkbox-wrapper  {
display: flex;
align-items: center;
}
.selected-item {
background: #F8F5FF;
}
::v-deep .ant-card-body {
padding: 0 !important;
}
</style>
